<template>
    <div class="more_List">
        <div class="mroe_content">
            <div class="more_warp">
                <div class="crumbs">
                    <span class="backHome" @click="$router.push('/home')" >公司首页 | </span> <span class="backHome"  @click="toguide(modular)">{{modular}}</span>
                </div>
                <!-- 列表区域 -->
                <div class="more_details">
                  <a-table
                    ref="table"
                    rowKey="id"
                    size="middle"
                    :columns="modular == '公司制度'||modular == '技术标准' ? columns2 : columns1"
                    :data-source="detailsList"
                    :pagination="ipagination"
                    :loading="loading"
                    :customRow="rowClick"
                    @change="handleTableChange"
                    >
                      <template slot="pressTitle" slot-scope="text">
                        <span class="sign"></span> {{text}}
                      </template>
                  </a-table>
                    <!-- <a-spin :spinning="loading">
                        <ul class="details_ul">
                            <li class="details_li" v-for="list in detailsList" :key="list.id" @click="$router.push({path:'details', query:{ id: list.id }})">
                                <span class="sign"></span>
                                <span class="title">{{ modular == '公司制度'? list.filename : list.pressTitle}}</span>
                                <span class="author">{{ modular == '公司制度'? list.createBy : list.writer}}</span>
                                <span class="time">{{modular == '公司制度'? list.createTime : list.releasetime}}</span>
                            </li>
                        </ul>
                        <div class="pagination">
                            <a-pagination size="small"
                                :current="current"
                                :show-total="(total, range) => `第${range[0]} - ${range[1]}条,  共 ${total}条`"
                                :total="total"
                                show-size-changer show-quick-jumper
                                @change="onChange"
                                @showSizeChange="sizeChange"
                                 />
                        </div>
                    </a-spin> -->

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getAction } from '@/api/manage'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { JeecgListMixin } from '@/views/mixins/mixin'
const toguideList = {
  '经营专题':'middle_warp',
  '一线故事':'middle_warp',
  '人文软控':'middle_warp',
  '热门文章':'middle_warp',
  '热门视频':'middle_warp',
  '公司制度':'last_warp',
  '技术标准':'last_warp',
  '党工团资讯':'last_warp',
  '软控报':'last_warp',
  '明星产品':'last_warp',
  '新软控人':'last_warp',
}

export default {
  mixins:[JeecgListMixin],
    data() {
        return {
            toguideList,
            detailsList: [],
            loading: false,
            userInfo: Vue.ls.get(USER_INFO),
            columns1: [
              {
                align: "left",
                dataIndex: 'pressTitle',
                scopedSlots: {customRender: 'pressTitle'}
              },
              {
                align: "center",
                dataIndex: 'writer'
              },
              {
                align: "center",
                dataIndex: 'releasetime'
              },
            ],
            columns2: [
              {
                align: "left",
                dataIndex: 'filename',
                scopedSlots: {customRender: 'pressTitle'}
              },
              {
                align: "center",
                dataIndex: 'createBy'
              },
              {
                align: "center",
                dataIndex: 'createTime'
              },
            ],
            /* 分页参数 */
            ipagination:{
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0
            },
            total: 0,
            size: 10,
            page: 1,
            current: 1,
        }

    },
    computed: {
      modular() {
        return this.$route.query.name
      }
    },
    created() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination, filters, sorter) {
          console.log('pagination: ', pagination);
          //分页、排序、筛选变化时触发
          //TODO 筛选
          if (Object.keys(sorter).length > 0) {
            this.isorter.column = sorter.field;
            this.isorter.order = "ascend" == sorter.order ? "asc" : "desc"
          }
          this.ipagination = pagination;
          this.page = pagination.current
          this.size = pagination.pageSize
          this.getList();
        },
        toguide(value) {
          let select  = toguideList[value]
          localStorage.setItem('id', select)
          this.$router.push('/home')
        },
        // 点击table 行
        rowClick(record) {
          return {
              on:{
                  click:() => {
                    if(this.$route.query.name==='公司制度') {
                      this.$router.push({path: 'details', query: {id: record.id, model: '公司制度'}})
                    }else if (this.$route.query.name==='技术标准'){
                      this.$router.push({path: 'details', query: {id: record.id, model: '技术标准'}})
                    }else{
                      this.$router.push({path: 'details', query: {id: record.id}})
                    }
                  }
              }
          }
        },
        getList() {
            console.log(this.modular)
            let formData = {}
            if(this.modular == '公司制度') {
              this.getSysList()
            } else if (this.modular == '技术标准'){
              this.getJSBZSysList()
            } else{
              if(this.modular == '热门文章') {
                formData = {
                  presssTatus:'已发布',
                  pressColumn: '新闻中心,经营专题,一线故事,人文软控',
                  column:'viewnum',
                  order:'desc'
                }
              } else {
                formData = {
                  presssTatus:'已发布',
                  column:'releasetime',
                  order:'desc',
                  pressColumn:this.modular
                }
              }
              this.loading = true
              getAction('/mesnac/zmap/zmaPrescenter/list',{pageNo: this.page, pageSize: this.size, ...formData }).then(res => {
                  if (res.success) {
                      console.log("res.success")
                      console.log(res.result)
                      this.detailsList = res.result.records
                      this.loading = false
                      this.ipagination.total = res.result.total
                      this.total = res.result.total
                  }
              })
            }
        },
        getSysList() {
          let that = this
          this.loading = true
          getAction('/mesnac/zmeResource/zmeResourceCenter/init').then(res=>{
            if(res.success){
              res.result.forEach(item => {
                if(item.filename == '公司制度') {
                  that.getChildList(item)
                }
              })
            }
          })
        },
        getJSBZSysList() {
            let that = this
            this.loading = true
            getAction('/mesnac/zmeResource/zmeResourceCenter/init').then(res=>{
                if(res.success){
                    res.result.forEach(item => {
                        if (item.filename == '技术标准'){
                            that.getJSBZChildList(item)
                        }
                    })
                }
            })
        },
        getChildList(record) {
            console.log('record: ', record);
            getAction('/mesnac/zmeResource/zmeResourceCenter/selectByParentId', {parentid: record.id, userid:this.userInfo.id}).then(res => {
                if(res.success) {
                    this.loading = false
                    this.detailsList = res.result
                    console.log('this.getChildList: ');
                    console.log(res.result);
                }else {
                    this.detailsList = []
                }
            })
        },
        getJSBZChildList(record) {
            console.log('record: ', record);
            getAction('/mesnac/zmeResource/zmeResourceCenter/sel_allFile', {rootid: record.id}).then(res => {
                if(res.success) {
                    this.loading = false
                    this.detailsList = res.result
                    console.log('this.getJSBZChildList: ');
                    console.log(res.result);
                }else {
                    this.detailsList = []
                }
            })
        },
        onChange(pageNumber) {
            console.log('pageNumber: ', pageNumber);
            this.page  = pageNumber
            this.current = pageNumber
            this.getList()
        },
        sizeChange (current, size) {
            console.log('current: ', current);
            console.log('size: ', size);
            this.size = size
            this.page = 1
            this.current = 1
            this.getList()
        }
    }
}
</script>

<style lang="less" scoped>
.more_List{
    width: 100%;
    // height: 1250px;
    padding-top: 25px;
    // background: url("~@/assets/img/home_bg.png") no-repeat top center / cover;
    background: #f6f6f6;
    .mroe_content{
        width: 1500px;
        margin: 0 auto;
        background: #fff;
        .more_warp{
            padding-top: 30px;
            padding-bottom: 50px;
            min-height: 800px;
            .crumbs{
                margin-left: 40px;
                font-size: 14px;
                color: #101010 10000%;
                .backHome{
                  cursor: pointer;
                  text-decoration: underline;
                }
            }
            .more_details{
                margin-top: 35px;
                padding: 0px 45px 0px 55px;
                /deep/ .ant-table-body{
                  .sign{
                      display: inline-block;
                      width: 10px;
                      height: 10px;
                      background: #BC0039 10000%;
                      border-radius: 50%;
                      // vertical-align: top;
                      margin-right: 20px;
                      // margin-top: 26px;
                  }
                }
                /deep/.ant-pagination {
                  .ant-pagination-next, .ant-pagination-prev {
                      .ant-pagination-item-link{
                          &:hover{
                              color: #BC0039;
                          }
                      }
                  }
                  .ant-pagination-next:focus .ant-pagination-item-link{
                      color: #BC0039;
                  }
                  .ant-pagination-item{
                      a:hover{
                          color: #BC0039;
                      }
                  }
                  .ant-pagination-item-active{
                      border-color: #BC0039;
                      a{color: #BC0039;}
                  }
                }
                .details_ul{
                    list-style: none;
                    .details_li{
                        cursor: pointer;
                        border-bottom: 1px solid #bbb;
                        height: 64px;
                        line-height: 64px;
                        font-size: 16px;
                        color: #101010 10000%;
                        .sign{
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            background: #BC0039 10000%;
                            border-radius: 50%;
                            vertical-align: top;
                            margin-right: 20px;
                            margin-top: 26px;
                        }
                        .title{
                            display: inline-block;
                            width: 800px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .author{
                            display: inline-block;
                            margin-right: 120px;
                            margin-left: 120px;
                            width: 120px;
                            vertical-align: top;
                        }
                        .time{
                            vertical-align: top;
                            text-align: right;
                            display: inline-block;
                            width: 150px;
                        }
                    }
                }
                .pagination{
                    text-align: right;
                    /deep/.ant-pagination {
                        .ant-pagination-next, .ant-pagination-prev {
                            .ant-pagination-item-link{
                                &:hover{
                                    color: #BC0039;
                                }
                            }
                        }
                        .ant-pagination-next:focus .ant-pagination-item-link{
                            color: #BC0039;
                        }
                        .ant-pagination-item{
                            a:hover{
                                color: #BC0039;
                            }
                        }
                        .ant-pagination-item-active{
                            border-color: #BC0039;
                            a{color: #BC0039;}
                        }
                    }
                }
            }
        }
    }
}
</style>


